/**
 * Team Photo Gallery
 * 
 * Shows the team photo gallery, consisting of photos that only this team
 * has uploaded. Only shows if the gallery setting is enabled.
 */

import React from 'react'
import TeamPhotoGallery from '../components/photos/team-photo-gallery'
import LoggedInWrapper from '../components/loggedinWrapper'
import AppContext from '../utils/context'
import { getCurrentTeamData } from '../utils/team-data'
import SvgArrow from '../components/svg/arrow'
import Link from '../components/link'

export default class TeamGalleryPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
    }
  }

  componentDidMount() {
    this.setState({ data: getCurrentTeamData() }, () => {
      this.setLoading(false)
    })
  }

  render() {
    const { data } = this.state

    return (
      <AppContext.Consumer>
        {({ refresh, setLoading }) => {
          this.setLoading = setLoading
          return (
            <LoggedInWrapper showPhotoPanel={true}>
              <div className="site-sub-header px-4 py-3">
                <div className="row">
                  <div className="col-2 d-flex align-items-center">
                    <Link to={`/`} className={`back-arrow`}>
                      <SvgArrow />
                    </Link>
                  </div>
                  <div className="col-10 text-right">
                    <h1 className={`title h5 smaller text-uppercase mb-0 d-inline-block`}>Team Photo Gallery</h1>
                  </div>
                </div>
              </div>
              <div className="photo-gallery-wrapper">
                {data.id !== undefined && (
                  <TeamPhotoGallery
                    key={data.id}
                    team_id={data.id}
                    access_code={data.access_code}
                    refresh={refresh}
                  />
                )}
              </div>
            </LoggedInWrapper>
          )
        }}
      </AppContext.Consumer>
    )
  }
}
