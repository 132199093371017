/**
 * Team Photo Gallery Component
 * 
 * A team specific photo gallery, so it only shows photos this team
 * has uploaded.
 */

import React from 'react'
import PropTypes from 'prop-types'
import API from '../../utils/api'
import Modal from 'react-bootstrap/Modal'
import Photo from './photo'
import { isTeamCaptain, getCurrentTeamData, teamRemovePhotoId } from '../../utils/team-data'
import { getCurrentUser } from '../../utils/auth'

import Slider from 'react-slick'

import SvgTrashcan from '../svg/trashcan'
import SVGLoader from '../svg/loader'

export default class TeamPhotoGallery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      images: [],
      active_image: {},
      modalShow: false,
      deleteModalShow: false,
      loading: true,
      is_used: false,
      deleting: false,
    }
    this.refreshPhotos = this.refreshPhotos.bind(this)
    this.setActivePhoto = this.setActivePhoto.bind(this)
    this.modalClose = this.modalClose.bind(this)
    this.deleteModalClose = this.deleteModalClose.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
    this.deletePhoto = this.deletePhoto.bind(this)
    this.goToActivePhoto = this.goToActivePhoto.bind(this)
    this.sliderContainer = React.createRef()
  }

  componentDidMount() {
    this.refreshPhotos()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refresh != this.props.refresh) {
      this.refreshPhotos()
    }
    if (this.state.modalShow) {
      console.log(`initializing touch overrides`)
      this.sliderContainer.current.addEventListener(`touchstart`, this.touchStart)
      this.sliderContainer.current.addEventListener(`touchmove`, this.preventTouch, { passive: false })
    } else {
      if (this.sliderContainer.current) {
        this.sliderContainer.current.removeEventListener(`touchstart`, this.touchStart)
        this.sliderContainer.current.removeEventListener(`touchmove`, this.preventTouch, { passive: false })
      }
    }
  }

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX
  }

  preventTouch(e) {
    const minValue = 5 // threshold

    let diff = e.touches[0].clientX - this.firstClientX

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(diff) > minValue) {
      // e.preventDefault()
      e.returnValue = false
      return false
    }
    return true
  }

  refreshPhotos() {
    API.get(`session/${this.props.access_code}/${this.props.team_id}/photos/`)
      .then(res => {
        const data = res.data
        this.setState({
          images: data.photos,
          loading: false,
        })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  setActivePhoto(photo) {
    this.setState(
      {
        active_image: photo,
        modalShow: true,
        is_used: this.photoUsed(photo),
      },
      () => this.goToActivePhoto()
    )
  }

  photoUsed(photo) {
    // check if this photo is used anywhere
    if (photo && photo.id !== undefined) {
      const photo_id = photo.id
      const team = getCurrentTeamData()
      if (team !== undefined) {
        if (team.checklist_answers !== null) {
          for (const checklist_id in team.checklist_answers) {
            const answers = team.checklist_answers[checklist_id]
            for (const answer_id in answers.checked) {
              const answer = answers.checked[answer_id]
              if (answer.photo !== undefined && answer.photo.id == photo_id) {
                return true
              }
            }
          }
        }
      }
    }
    return false
  }

  goToActivePhoto() {
    let index = 0
    let photo_id = this.state.active_image.id
    this.state.images.map(function(image, i) {
      if (image.id == photo_id) {
        index = i
      }
    })
    this.imageslider.slickGoTo(index, true)
  }

  modalClose() {
    this.setState({
      modalShow: false,
    })
  }

  deleteModalClose() {
    this.setState(
      {
        deleteModalShow: false,
        modalShow: true,
      },
      () => this.goToActivePhoto()
    )
  }

  confirmDelete(photo) {
    this.setState({
      active_image: photo,
      is_used: this.photoUsed(photo),
      deleteModalShow: true,
      modalShow: false,
    })
  }

  deletePhoto(id) {
    console.log(`deleting photo ID: ${id}`)
    this.setState({ deleting: true })
    API.delete(`session/${this.props.access_code}/${this.props.team_id}/photos/${id}/`)
      .then(() => {
        teamRemovePhotoId(id)
        this.setState({
          active_image: {},
          deleteModalShow: false,
          modalShow: false,
          is_used: false,
          deleting: false,
        })
        this.refreshPhotos()
      })
      .catch(function(error) {
        console.log(error)
        this.setState({
          active_image: {},
          deleteModalShow: false,
          modalShow: false,
          is_used: false,
          deleting: false,
        })
      })
  }

  render() {
    const { loading, images, deleting } = this.state
    const { selectedPhoto } = this.props
    const user = getCurrentUser()
    const is_captain = isTeamCaptain()
    let select_func = this.setActivePhoto
    if (this.props.onClick) {
      select_func = this.props.onClick
    }

    var sliderSettings = {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      useTransform: true,
    }

    return (
      <>
        <div className={`photo-list row no-gutters`}>
          {loading && (
            <div className="col-6 text-center mt-5">
              <p>Loading photos...</p>
            </div>
          )}
          {images !== undefined &&
            images.map((photo, i) => (
              <div key={i} className={`col-4 py-0 px-0 text-center`}>
                <Photo photo={photo} selected={selectedPhoto && photo.id == selectedPhoto.id} onClick={() => select_func(photo)} />
              </div>
            ))}
          {!loading && images.length == 0 && (
            <div className="col-12 text-center mt-5">
              <p className={`lead`}>
                <strong className={`d-block text-uppercase`}>No photos uploaded yet!</strong> Why don't you add a few?
              </p>
            </div>
          )}
        </div>
        <Modal show={this.state.modalShow} onHide={this.modalClose} centered className="photo-modal" aria-labelledby="photo-slider-modal-title">
          <h3 id="photo-slider-modal-title" className="sr-only">Photo Slider</h3>
          <Modal.Body className={`text-center text-uppercase px-0 py-0`}>
            <div ref={this.sliderContainer}>
              <Slider ref={slider => (this.imageslider = slider)} {...sliderSettings} className="images-slider">
                {images !== undefined &&
                  images.map((photo, i) => (
                    <div key={i} className={`position-relative px-2`}>
                      <img className="img-fluid" src={photo.image} />
                      {(is_captain || photo.author == user.name) && (
                        <button className={`position-absolute btn btn-trash`} onClick={() => this.confirmDelete(photo)} title="Delete">
                          <SvgTrashcan />
                        </button>
                      )}
                    </div>
                  ))}
              </Slider>
            </div>
            <button className={`btn btn-block btn-cancel btn-tiny text-uppercase`} onClick={this.modalClose}>
              Cancel
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.deleteModalShow} onHide={this.deleteModalClose} centered aria-labelledby="delete-photo-modal-title">
          <Modal.Header closeButton>
            <Modal.Title id="delete-photo-modal-title" className={`text-center`}>
              Are you sure you want to <br />
              delete this photo?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={``}>
            {this.state.is_used && (
              <p>
                This photo is being used in a checklist. Deleting this photo will remove it from all checklists and
                questions.
              </p>
            )}
            {deleting ? (
              <p className={`text-center pt-4`}>
                <SVGLoader />
              </p>
            ) : (
              <button
                className={`btn btn-block btn-danger`}
                onClick={() => this.deletePhoto(this.state.active_image.id)}>
                Delete
              </button>
            )}
            <button className={`btn btn-block btn-cancel btn-tiny`} onClick={this.deleteModalClose}>
              Cancel
            </button>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

TeamPhotoGallery.propTypes = {
  access_code: PropTypes.string,
  team_id: PropTypes.number,
  onClick: PropTypes.func,
}

TeamPhotoGallery.defaultProps = {
  access_code: ``,
  team_id: ``,
}
